import React from 'react';

import { IContainer } from '../../../../../../common/container/IContainer';
import { ConstructorType, inject, injectValue } from '../../../../../../common/container/inject';
import { IError } from '../../../../../../common/error/IError';
import { IValidationErrorData } from '../../../../../../common/error/IValidationErrorData';
import {
  IApplicationAdditionalColumnData,
  IApplicationDataModel,
  IApplicationStatusData,
} from '../../../../../../service/application/data/IApplicationDataModel';
import {
  ApplicationAcceptStatus,
  IApplicationModel,
} from '../../../../../../service/application/entity/IApplicationModel';
import { IApplicationTemplateModel } from '../../../../../../service/application/template/IApplicationTemplateModel';
import { IChatModel } from '../../../../../../service/chat/entity/IChatModel';
import { IMessageModel } from '../../../../../../service/chat/message/IMessageModel';
import { getServerNowDate } from '../../../../../../service/date/since';
import { UserProjectRoleAction } from '../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { TaskManagerConnectionState } from '../../../../../../service/taskManager/ITaskManager';
import { LayoutNotificationType } from '../../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ProjectFormDomain } from '../../../project/form/store/ProjectFormDomain';
import { StatusAutocomplete } from '../parts/requirements/ActiveRequirementsTableParts';
import { ApplicationDetailsMode } from './ApplicationDetailsMode';
import { ApplicationDetailsType } from './ApplicationDetailsType';
import { ApplicationDetailUI, defaultEditApplication } from './ApplicationDetailUI';
import { ApplicationRequirementFilterDomain } from './filter/ApplicationRequirementFilterDomain';
import { RequirementViewModel, RequirementViewModelState } from './RequirementViewModel';
import { ICoreSearchAudit } from '../../../../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { SpecificationCategoryType } from '../../../../../../service/specification/category/ISpecificationCategoryModelConditionMap';

export interface IDownloadReportProps {
  format: string,
  versionNumber?: string,
  audit?: ICoreSearchAudit,
  isNeedColor?: boolean,
  reportName?: string,
}
interface IAppReqData {
  appReqId: string;
  requirement: RequirementViewModel;
}

export class ApplicationDetailDomain {
  public filterDomain: ApplicationRequirementFilterDomain;

  private isLoadedApplicationDependencies: boolean = false;
  private isLoadedApplicationDataDependencies: boolean = false;

  public projectFormDomain: ProjectFormDomain | null = null;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui: ApplicationDetailUI = new (injectValue<ConstructorType<ApplicationDetailUI>>(ApplicationDetailUI))(),
    protected router: IRouterService = inject<IRouterService>(RouterServiceToken),
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    filterDomain: ApplicationRequirementFilterDomain | null = null,
    private container: IContainer | null = null,
    public performerTypeId: string | undefined = ui?.editApplication?.entity?.performerTypeId || undefined,
    public editApplicationCopy: any = ui?.editApplication?.entity || undefined,
    public isTemplateJira = injectPrimitive<boolean>(false),
    public isEmptyJiraFilds = injectPrimitive<boolean>(false),
    public isSyncButtonBlocked = injectPrimitive<boolean>(false),
  ) {
    this.isTemplateJira.setValue(false);
    this.filterDomain = filterDomain || new ApplicationRequirementFilterDomain(this.rootService, this);
  }

  startPage = async (
    container: IContainer | null,
    mode: ApplicationDetailsMode,
    applicationId?: string,
    projectId?: string,
    type?: ApplicationDetailsType,
    projectFormDomain?: ProjectFormDomain,
  ) => {
    this.ui.isCompletePageStartLoading.setValue(false);
    this.projectFormDomain = projectFormDomain || null;
    this.container = container;
    this.ui.mode.setValue(mode);
    this.ui.type.setValue(type || ApplicationDetailsType.entity);
    applicationId = applicationId || this.ui.application.entity.id;
    projectId = projectId || this.ui.application.entity.projectId;

    await this.loadApplication(applicationId, projectId);

    this.ui.removeSelection();

    const application = this.ui.application.entity;
    const applicationData = this.ui.applicationData.entity;

    await Promise.all([this.loadApplicationDependencies(application), this.loadApplicationJiraSettings()]);
    await this.loadApplicationDataDependencies(applicationData);
    await this.filterDomain.start();

    if (!this.ui.isFirstBootLoaded.value) {
      this.setTableSettings();
      this.ui.isFirstBootLoaded.setValue(true);
    }

    if (mode === ApplicationDetailsMode.edit) {
      await this.startEditApplication();
      await this.getSpecificationsTextFieldsData()
    }

    this.ui.isCompletePageStartLoading.setValue(true);
  };

  async resyncAllReqsToJira(requirementForResync: string[]) {
    // for (let i = 0; i < requirementForResync.length; i++) {
    //   await this.resyncIssue(requirementForResync[i]);
    // }

    // await this.reloadJiraLinks();
  }

  setApplicationAcceptStatus(acceptStatus: any): void {
    if (JSON.stringify(this.ui.editApplication.entity) === JSON.stringify(defaultEditApplication)) {
      this.ui.editApplication.setEntity({ ...this.ui.application.entity });
    }
    this.ui.editApplication.entity.acceptStatus = acceptStatus;
  }

  setTableSettings = () => {
    this.ui.setRequirementCategoriesValues();
    this.setStatusesColumns();
    this.setPrioritiesSettings();
  };

  private async loadApplication(applicationId?: string, projectId?: string) {
    const application = await this.getApplicationData(this.ui.type.value, applicationId, projectId);
    const activeUserId = this.layoutDomain.ui.activeUser.entity.id;
    const [project, applicationData, userRoles] = await Promise.all([
      application.projectId ? await this.rootService.project.entity.getById(application.projectId) : {},
      application.dataId
        ? await this.rootService.application.data.getById(application.dataId)
        : await this.rootService.application.data.preview(application),
      await this.rootService.projectRole.entity.search({ limit: 10000 }),
    ]);

    this.ui.application.setEntity(application);
    this.ui.project.setEntity(project);
    this.ui.applicationData.setEntity(applicationData);

    const userProjectRoleId = project.rolesMap?.data?.find((roleData) => roleData.userId === activeUserId)?.roleId;
    const userProjectRole = userRoles.data.find((userRole) => userRole.id === userProjectRoleId);
    const allProjectRoles = userRoles.data;

    const userProjectRoleActions = userProjectRole?.permissions.data
      .filter((item) => item.isGranted === true)
      .map((item) => item.action);

    const isCanSeeRegulators =
      !!userProjectRoleActions?.includes(UserProjectRoleAction.showRegulators) ||
      this.layoutDomain.ui.activeUser.entity.allowedPermissions.includes(
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-show-regulators'],
      ) ||
      this.layoutDomain.ui.activeUser.entity.allowedPermissions.includes(
        UserSystemRoleModelPermissionMap['global-allow-any'],
      );

    const isCanEditJira =
      this.layoutDomain.ui.activeUser.entity.allowedPermissions.includes(
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-jira-intergration'],
      ) ||
      this.layoutDomain.ui.activeUser.entity.allowedPermissions.includes(
        UserSystemRoleModelPermissionMap['global-allow-any'],
      ) ||
      !!userProjectRoleActions?.includes(UserProjectRoleAction.jiraIntegration);

    const isCanUpdate =
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
      (this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['settings-application-template-update'],
      ]) &&
        this.ui.type.value === ApplicationDetailsType.template) ||
      this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-application'],
      ]) ||
      !!userProjectRoleActions?.includes(UserProjectRoleAction.editApplication);

    const isCanDelete =
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
      this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-application'],
      ]) ||
      !!userProjectRoleActions?.includes(UserProjectRoleAction.editApplication) ||
      (this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['settings-application-template-delete'],
      ]) &&
        this.ui.type.value === ApplicationDetailsType.template);

    const isCanCreate =
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]) ||
      this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-application'],
      ]) ||
      !!userProjectRoleActions?.includes(UserProjectRoleAction.creteApplication) ||
      (this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['settings-application-template-create'],
      ]) &&
        this.ui.type.value === ApplicationDetailsType.template);

    const isAdmin = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);

    this.ui.allProjectRoles.setValue(allProjectRoles);
    this.ui.isCanUpdate.setValue(isCanUpdate);
    this.ui.isCanDelete.setValue(isCanDelete);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanSeeRegulators.setValue(isCanSeeRegulators);
    this.ui.isAdmin.setValue(isAdmin);
    this.ui.isCanEditJira.setValue(isCanEditJira);
    this.ui.activeUserId.setValue(activeUserId);
    this.ui.userProjectRoleActions.setValue(userProjectRoleActions || []);
    this.ui.activeUserProjectRoleId.setValue(userProjectRoleId || '');
  }

  async getApplicationData(type: ApplicationDetailsType, applicationId?: string, projectId?: string) {
    if (type === ApplicationDetailsType.entity) {
      return applicationId
        ? await this.rootService.application.entity.getById(applicationId)
        : this.newApplicationModel(projectId);
    } else {
      return applicationId
        ? ((await this.rootService.application.template.getById(applicationId)) as IApplicationModel)
        : (this.newApplicationTemplate() as IApplicationModel);
    }
  }

  private async loadApplicationDataPreview() {
    const application = this.ui.editApplication.entity;
    const applicationDataPreview = await this.rootService.application.data.preview(application);

    await this.loadApplicationDataDependencies(applicationDataPreview);

    this.ui.previewApplicationData.setEntity(applicationDataPreview);
    this.setApplicationRequirements(applicationDataPreview);

    this.ui.activeStatusFilter.setValue(null);
    this.editApplicationCopy = this.ui?.editApplication?.entity;
  }

  private newApplicationModel(projectId?: string): IApplicationModel {
    return {
      name: '',
      projectId,
      specificationsIds: [],
    };
  }

  public checkJiraConnectionByStates() {
    const isConnectedJira =
      this.ui.jiraValidationErrors.list.length === 0 &&
      !!this.ui.editJiraTaskManager.entity.settings?.value?.projectId &&
      !!this.ui.editJiraTaskManager.entity.settings?.value.issue?.type?.id;

    this.ui.editJiraTaskManager.entity.connectionState = isConnectedJira
      ? TaskManagerConnectionState.connected
      : TaskManagerConnectionState.notConnected;
  }

  public applyTemplate = async () => {
    const taskManagerServer = await this.rootService.taskManager.server
      .getById(this.ui.editJiraTaskManager.entity.serverId || '')
    if (taskManagerServer.connectionTemplate.settings) {
      const projectId = this.ui.editJiraTaskManager.entity.settings?.value?.projectId;
      if (taskManagerServer.connectionTemplate.settings.value?.projectId) {
        taskManagerServer.connectionTemplate.settings.value.projectId = projectId;
      }
      if (taskManagerServer.connectionTemplate.settings?.value?.issue?.type?.id) {
        taskManagerServer.connectionTemplate.settings.value.issue.type.id = this.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id;
      }
      this.ui.editJiraTaskManager.entity.settings = taskManagerServer.connectionTemplate.settings;
      this.isTemplateJira.setValue(true);
    }
  }

  private newApplicationTemplate(): IApplicationTemplateModel {
    return {
      name: '',
      specificationsIds: [],
      isDefault: false,
    };
  }

  private async loadApplicationDataDependencies(applicationData: IApplicationDataModel) {
    const emptySearch: any = { data: [] };
    const isLoadStatuses = applicationData.statusesIds && applicationData.statusesIds.length > 0;
    const isLoadAdditionalColumns =
      applicationData.additionalColumnsIds && applicationData.additionalColumnsIds?.length > 0;
    const isLoadAdditionalColumnsValues =
      applicationData.additionalColumnsContentIds && applicationData.additionalColumnsContentIds.length > 0;
    const isLoadVendorRequirements =
      applicationData.vendorRequirementsIds && applicationData.vendorRequirementsIds?.length > 0;

    if (!this.isLoadedApplicationDataDependencies) {
      const [requirements, requirementsCategories, statusesValues, vendors, chatsCountersData, jiraLinks] =
        await Promise.all([
          this.rootService.requirement.entity.search({ limit: 100000 }),
          this.rootService.requirement.category.search({ limit: 100000 }),
          this.rootService.status.value.search({ limit: 100000 }),
          this.rootService.vendor.entity.search({ limit: 100000 }),
          applicationData.id
            ? this.rootService.application.data.getApplicationDataChatCounters(applicationData.id || '')
            : null,
          applicationData.id
            ? this.rootService.application.data.getApplicationJiraLinks(applicationData.id || '')
            : { links: [] },
        ]);

      if (applicationData.id) {
        const appReq = await this.rootService.applicationRequirement.entity.getAppReqByAppId(applicationData.id);
        this.ui.applicationReq.setList(appReq);
      }
      this.ui.vendors.setList(vendors.data);
      this.ui.requirements.setList(requirements.data);
      this.ui.requirementsCategories.setList(requirementsCategories.data);
      this.ui.statusesValues.setList(statusesValues.data);
      this.isLoadedApplicationDataDependencies = true;
      this.ui.applicationNewMessagesState.setValue(chatsCountersData);
      this.ui.applicationJiraLinks.setValue(jiraLinks.links);
    }

    const [statuses, additionalColumns, additionalColumnsContent, vendorRequirements] = await Promise.all([
      isLoadStatuses
        ? this.rootService.status.entity.search({
          limit: 100000,
          filter: {
            ids: { in: applicationData.statusesIds },
            isActive: true,
          },
        })
        : emptySearch,
      isLoadAdditionalColumns
        ? this.rootService.additionalColumn.entity.search({
          limit: 100000,
          filter: {
            ids: { in: applicationData.additionalColumnsIds },
            isActive: true,
          },
        })
        : emptySearch,
      isLoadAdditionalColumnsValues
        ? await this.rootService.additionalColumn.content.search({
          limit: 100000,
          filter: {
            ids: { in: applicationData.additionalColumnsContentIds },
            isActive: true,
          },
        })
        : emptySearch,
      isLoadVendorRequirements
        ? await this.rootService.requirement.vendor.search({
          limit: 100000,
          filter: {
            ids: { in: applicationData.vendorRequirementsIds },
            isActive: true,
          },
        })
        : emptySearch,
    ]);
    this.ui.statuses.setList(statuses.data);
    this.ui.additionalColumns.setList(additionalColumns.data);
    this.ui.additionalColumnsContent.setList(additionalColumnsContent.data);
    this.ui.vendorRequirements.setList(vendorRequirements.data);
    this.setApplicationRequirements(applicationData);
  }

  public async reloadJiraLinks() {
    const jiraLinks = (await this.ui.applicationData.entity.id)
      ? await this.rootService.application.data.getApplicationJiraLinks(this.ui.applicationData.entity.id || '')
      : { links: [] };
    this.ui.applicationJiraLinks.setValue(jiraLinks.links);
  }

  public async loadApplicationJiraSettings() {
    const applicationId = this.ui.application.entity.id;
    if (applicationId) {
      const jiraTaskManager = await this.rootService.application.jira.getJiraCredentialsByApplicationId(
        applicationId || '',
      );
      //@todo refactoring
      if (jiraTaskManager.settings?.value?.issue && jiraTaskManager.settings.value.issue.namePrefix === null) {
        jiraTaskManager.settings.value.issue.namePrefix = '';
      }
      this.ui.editJiraTaskManager.setEntity(jiraTaskManager);

      this.checkJiraConnectionByStates();
    } else {
      //@todo refactoring
      if (
        this.ui.editJiraTaskManager.entity.settings?.value?.issue &&
        this.ui.editJiraTaskManager.entity.settings.value.issue.namePrefix === null
      ) {
        this.ui.editJiraTaskManager.entity.settings.value.issue.namePrefix = '';
      }
    }
  }

  async loadStatusValuesSyncAvailableMap(requirementId: string, statusId: string) {
    const applicationIdFromUI = this.ui.application.entity.id;
    const jiraTaskManager = this.ui.editJiraTaskManager.entity;
    const isStatusMapped = jiraTaskManager.settings?.value?.statusesMap?.find(
      (statusMap) => statusMap.internalStatusId === statusId,
    );
    if (jiraTaskManager.id && isStatusMapped) {
      const requirementTransitions = await this.rootService.application.jira.getJiraTransitionsForRequirement(
        applicationIdFromUI || '',
        requirementId,
        jiraTaskManager,
      );
      const mappedStatusValues = this.ui.editJiraTaskManager.entity.settings?.value?.statusesMap || [];
      return mappedStatusValues.map((statusValueMap) => {
        return {
          statusValueId: statusValueMap.internalStatusValueId,
          isAvailable: !!requirementTransitions.find(
            (transition) => transition.externalStatusId === statusValueMap.externalStatusId,
          ),
        };
      });
    } else {
      return [];
    }
  }

  public async saveApplicationJiraSettings(applicationId?: string) {
    const applicationIdFromUI = this.ui.application.entity.id;
    const performer = this.ui.editPerformerTypes.list.find(
      (item) => item.id === this.ui.editApplication.entity.performerTypeId,
    );
    if (performer) {
      const statuses = this.ui.editStatuses.list.filter((item) => performer.statusesIds?.includes(item.id || ''));

      if (!statuses.length) {
        if (this.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds) {
          this.ui.editJiraTaskManager.entity.settings.value.allowedInternalStatusesIds = [];
        }
      }
    } else {
      if (this.ui.editJiraTaskManager.entity.settings?.value?.allowedInternalStatusesIds) {
        this.ui.editJiraTaskManager.entity.settings.value.allowedInternalStatusesIds = [];
      }
    }

    const jiraTaskManager = this.ui.editJiraTaskManager.entity;
    await this.rootService.application.jira
      .saveJiraSettingsToApplication(applicationId || applicationIdFromUI || '', jiraTaskManager)
      .catch((error) => this.jiraFormErrorsHandler(error, false, false, false, false));
  }

  public async loadJiraProjects() {
    this.ui.isLoadingJiraProjects.setValue(true);
    // this.ui.jiraProjects.setValue([]);

    try {
      const jiraProjects = await this.rootService.application.jira.loadProjects(this.ui.editJiraTaskManager.entity);
      this.ui.jiraProjects.setValue(jiraProjects);
    } catch (error) {
      this.jiraFormErrorsHandler(error);
    }

    this.ui.isLoadingJiraProjects.setValue(false);
  }

  public async loadJiraStatuses() {
    this.ui.isLoadingJiraStatuses.setValue(true);
    this.ui.jiraProjectStatuses.setValue([]);

    try {
      const jiraProjectStatuses = await this.rootService.application.jira.getProjectStatuses(
        this.ui.editJiraTaskManager.entity,
      );
      this.ui.jiraProjectStatuses.setValue(jiraProjectStatuses);
    } catch (error) {
      this.jiraFormErrorsHandler(error);
    }
    this.ui.isLoadingJiraStatuses.setValue(false);
  }

  public async loadJiraPriorities() {
    this.ui.isLoadingJiraPriorities.setValue(true);
    this.ui.jiraProjectPriorities.setValue([]);

    try {
      const jiraProjectPriorities = await this.rootService.application.jira.getProjectPriorities(
        this.ui.editJiraTaskManager.entity,
      );
      this.ui.jiraProjectPriorities.setValue(jiraProjectPriorities);
    } catch (error) {
      this.jiraFormErrorsHandler(error);
    }
    this.ui.isLoadingJiraPriorities.setValue(false);
  }

  public async loadJiraIssuesFields() {
    this.ui.isLoadingJiraIssuesFields.setValue(true);

    try {
      const jiraIssuesFields = await this.rootService.application.jira.getIssuesFields(
        this.ui.editJiraTaskManager.entity,
      );
      this.ui.jiraIssuesFields.setValue(jiraIssuesFields);

      if (
        this.ui.editJiraTaskManager.entity?.settings?.value &&
        !this.ui.editJiraTaskManager.entity.settings?.value?.fields
      ) {
        this.ui.editJiraTaskManager.entity.settings.value.fields = [];
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error);
    }
    this.ui.isLoadingJiraIssuesFields.setValue(false);
  }

  public async setPrioritiesSettings() {
    this.ui.jiraProjectTagsForPriority.setValue([]);
    const priorityTagCategoryName = 'критичность требования';
    // const highPriorityTagName = 'высокая';
    // const midPriorityTagName = 'средняя';
    // const lowPriorityTagName = 'низкая';

    const priorityTagCategory = this.ui.editTagsCategories.list.find(
      (tagCategory) => tagCategory.name?.toLocaleLowerCase() === priorityTagCategoryName,
    );

    this.ui.editTags.list.forEach((tag) => {
      if (tag.categoryId === priorityTagCategory?.id) {
        this.ui.jiraProjectTagsForPriority.value.push({
          name: `${priorityTagCategory.name}: ${tag.name}`,
          orderIndex: 0,
          isDefault: false,
          tag: tag,
        });
      }
    })

    // if (priorityTagCategory) {
    //   const lowPriorityTag = this.ui.editTags.list.find((tag) => tag.name?.toLocaleLowerCase() === lowPriorityTagName);
    //   const midPriorityTag = this.ui.editTags.list.find((tag) => tag.name?.toLocaleLowerCase() === midPriorityTagName);
    //   const highPriorityTag = this.ui.editTags.list.find(
    //     (tag) => tag.name?.toLocaleLowerCase() === highPriorityTagName,
    //   );
    //   if (lowPriorityTag) {
    //     this.ui.jiraProjectTagsForPriority.value.push({
    //       name: `${priorityTagCategory.name}: ${lowPriorityTag.name}`,
    //       orderIndex: 0,
    //       isDefault: false,
    //       tag: lowPriorityTag,
    //     });
    //   }
    //   if (midPriorityTag) {
    //     this.ui.jiraProjectTagsForPriority.value.push({
    //       name: `${priorityTagCategory.name}: ${midPriorityTag.name}`,
    //       orderIndex: 1,
    //       isDefault: false,
    //       tag: midPriorityTag,
    //     });
    //   }
    //   if (highPriorityTag) {
    //     this.ui.jiraProjectTagsForPriority.value.push({
    //       name: `${priorityTagCategory.name}: ${highPriorityTag.name}`,
    //       orderIndex: 2,
    //       isDefault: true,
    //       tag: highPriorityTag,
    //     });
    //   }
    // }
  }

  public async loadJiraIssueTypes() {
    this.ui.isLoadingJiraIssueTypes.setValue(true);
    this.ui.jiraIssueTypes.setValue([]);

    try {
      const jiraIssueTypes = await this.rootService.application.jira.getIssueTypes(this.ui.editJiraTaskManager.entity);
      this.ui.jiraIssueTypes.setValue(jiraIssueTypes);
    } catch (error) {
      this.jiraFormErrorsHandler(error);
    }
    this.ui.isLoadingJiraIssueTypes.setValue(false);
  }

  public async resyncIssue(requirementId: string) {
    await this.rootService.application.jira.recreateIssueInTaskManager(
      this.ui.application.entity?.id || '',
      requirementId,
    );

    await this.reloadJiraLinks();
  }

  rerenderRequirements() {
    this.setApplicationRequirements(this.ui.applicationData.entity);
  }

  setApplicationRequirements(applicationData: IApplicationDataModel) {
    const newestRequirements = this.ui.getRequirementByIds(applicationData?.newestRequirementsIds);
    const deletedRequirements = this.ui.getRequirementByIds(applicationData?.deletedRequirementsIds);
    applicationData.savedRequirementsIds = (applicationData?.savedRequirementsIds || []).filter(
      (requirementId) => !applicationData?.deletedRequirementsIds?.includes(requirementId),
    );

    const savedRequirements = this.ui.getRequirementByIds(applicationData?.savedRequirementsIds);

    const isCanSeeAllRequirements = this.layoutDomain.userHaveAnyAccess(
      [UserSystemRoleModelPermissionMap['project-not-in-team-permission-show-unverified-requirements-access']],
      {
        id: this.ui.project.entity.id || '',
        permissions: [UserProjectRoleAction.showUnverifiedRequirements],
      },
    );

    if (!isCanSeeAllRequirements) {
      this.ui.setTableRequirements(savedRequirements, [], deletedRequirements);
    } else {
      this.ui.setTableRequirements(savedRequirements, newestRequirements, deletedRequirements);
    }
  }

  private async loadApplicationDependencies(application: IApplicationModel) {
    const applicationSpecificationsIds = application.specificationsIds || null;
    const applicationPerformerId = application.performerTypeId || null;

    if (!this.isLoadedApplicationDependencies) {
      const [
        editSpecifications,
        editSpecificationsCategories,
        editStatuses,
        editStatusesValues,
        editPerformerTypes,
        applicationTemplates,
        tagCategories,
        tags,
        taskManagerServers,
        users,
      ] = await Promise.all([
        this.rootService.specification.entity.search({ limit: 100000 }),
        this.rootService.specification.category.search({ limit: 100000 }),
        this.rootService.status.entity.search({ limit: 100000 }),
        this.rootService.status.value.search({ limit: 100000 }),
        this.rootService.performer.type.search({ limit: 100000 }),
        this.rootService.application.template.search({ limit: 10000 }),
        this.rootService.tag.category.search({ limit: 10000 }),
        this.rootService.tag.entity.search({ limit: 10000 }),
        this.rootService.taskManager.server.search({ limit: 10000 }),
        this.rootService.user.entity.search({ limit: 10000 }),
      ]);

      this.ui.applicationTemplates.setList(applicationTemplates?.data || []);
      this.ui.editSpecifications.setList((editSpecifications?.data as any) || []);
      this.ui.editSpecificationsCategories.setList(editSpecificationsCategories.data);
      this.ui.editTags.setList((tags?.data as any) || []);
      this.ui.editUsers.setList((users?.data as any) || []);
      this.ui.editTagsCategories.setList(tagCategories.data);
      this.ui.editStatuses.setList(editStatuses.data);
      this.ui.editStatusesValues.setList(editStatusesValues.data);
      this.ui.editPerformerTypes.setList(editPerformerTypes.data);
      this.ui.taskManagerServers.setList(taskManagerServers.data);

      this.isLoadedApplicationDependencies = true;
    }

    const [applicationSpecifications, applicationPerformerType] = await Promise.all([
      applicationSpecificationsIds && applicationSpecificationsIds.length > 0
        ? this.rootService.specification.entity.search({
          limit: 100000,
          filter: { ids: { in: applicationSpecificationsIds } },
        })
        : null,
      applicationPerformerId ? this.rootService.performer.type.getById(applicationPerformerId) : null,
    ]);

    const applicationSpecificationsCategoriesIds: string[] =
      applicationSpecifications?.data.reduce((ids: string[], specification) => {
        //@ts-ignore
        if (!ids.includes(specification.categoryId)) {
          //@ts-ignore
          ids.push(specification.categoryId);
        }
        return ids;
      }, []) || [];

    const applicationSpecificationsCategories =
      applicationSpecificationsCategoriesIds?.length > 0
        ? await this.rootService.specification.category.search({
          limit: 100000,
          filter: { ids: { in: applicationSpecificationsCategoriesIds } },
        })
        : { data: [] };
    this.ui.applicationSpecificationsCategories.setList(applicationSpecificationsCategories.data);
    //@ts-ignore
    this.ui.applicationSpecifications.setList(applicationSpecifications?.data || []);
    this.ui.applicationPerformerType.setEntity(applicationPerformerType || {});
  }

  startEditApplication = async () => {
    this.ui.editApplication.setEntity(this.ui.application.entity);
    this.ui.previewApplicationData.setEntity(this.ui.applicationData.entity);
    this.ui.mode.setValue(ApplicationDetailsMode.edit);
    await this.updateSpecificationsDependence()
    await this.getSpecificationsTextFieldsData()
  };

  onClickEditApplication = () => {
    if (this.ui.application.entity.isUnitedWithProject) {
      this.router.goTo(
        `/project/edit/${this.ui.application.entity.projectId}/application/${this.ui.application.entity.id}`,
      );
    } else {
      this.startEditApplication();
    }
  };

  setApplicationTemplateHandler = ({ value }: any) => {
    this.setApplicationTemplate(value);
  };
  setApplicationTemplate = (templateId?: string | null) => {
    this.ui.selectedApplicationTemplate.setValue(templateId || '');
    //@ts-ignore
    this.ui.editApplication.entity.templateId = templateId || null;
    const template = this.ui.applicationTemplates.list.find((template) => template.id === templateId);
    if (template) {
      this.ui.editApplication.setEntity({
        ...this.ui.editApplication.entity,
        specificationsIds: template?.specificationsIds || [],
        performerTypeId: template?.performerTypeId,
        customInformation: template?.customInformation, //SDA-1930  удалить после проверки и принятия задачи
      });
      this.clearJiraLoadedDataValues(false, false, true, true);
      this.getSpecificationsTextFieldsData(template.id as string)
    } else {
      this.ui.editApplication.setEntity({
        ...this.ui.editApplication.entity,
        specificationsIds: [],
        performerTypeId: undefined,
        customInformation: { fields: [] }, //SDA-1930  удалить после проверки и принятия задачи
      });
      this.getSpecificationsTextFieldsData()
    }
    // this.loadApplicationDataPreview();
  };

  saveApplicationStatuses = async (statusesUpdateData: IApplicationStatusData) => {
    const applicationId = this.ui.applicationData.entity.id;
    if (applicationId && statusesUpdateData?.data?.length > 0) {
      await this.rootService.application.data.updateStatuses(statusesUpdateData, applicationId);
      this.ui.removeSelection();
      this.ui.isCompletePageStartLoading.setValue(false);
      this.startPage(this.container, ApplicationDetailsMode.view);
      this.ui.isCompletePageStartLoading.setValue(true);
    }
  };

  saveApplicationAdditionalColumnData = async (additionalColumnData: IApplicationAdditionalColumnData) => {
    const applicationId = this.ui.applicationData.entity.id;
    if (applicationId && additionalColumnData?.data?.length > 0) {
      await this.rootService.application.data.updateAdditionalColumn(additionalColumnData, applicationId);
    }
  };

  validateApplicationCategoryTextFields = async () => {
    const textFieldsInCurrentApplication = this.ui.specificationsTextFieldsValues.list || []
    const specificationsCategories = this.ui.editSpecificationsCategories.list
    const validationErrors: string[] = []
    if (textFieldsInCurrentApplication.length) {
      specificationsCategories.forEach(category => {
        if (category.conditionsTextMap?.isRequired && category.isActive) {
          const currentTextField = textFieldsInCurrentApplication.find(field => field.categoryId === category.id)
          if (!currentTextField || !currentTextField?.name || currentTextField?.name.length < 2) {
            validationErrors.push(category.id as string)
          }
        }
      })
    } else {
      specificationsCategories.forEach(category => {
        if (category.conditionsTextMap?.isRequired && category.isActive) {
          validationErrors.push(category.id as string)
        }
      })
    }
    if (validationErrors.length) {
      this.ui.specificationsTextFieldsErrors.setValue(validationErrors)
    } else {
      this.ui.specificationsTextFieldsErrors.setValue([])
    }
  };

  // TODO refactoring
  saveApplication = async () => {
    this.validateApplicationCategoryTextFields()
    if (this.ui.specificationsTextFieldsErrors.value.length) {
      return
    }
    if (!this.ui.editApplication.entity.performerTypeId) {
      this.ui.editApplication.entity.acceptStatus = ApplicationAcceptStatus.draft;
      this.getValidationErrorForEmptyPerformerId();
      return;
    }
    let isFinalySave = true;
    if (this.projectFormDomain) {
      await this.projectFormDomain.save(false);
      //@ts-ignore
      isFinalySave = this.projectFormDomain?.isFinalySave?.value ?? true;
      if (!this.ui.editApplication.entity.performerTypeId) {
        this.ui.editApplication.setEntity(this.editApplicationCopy);
        this.ui.editApplication.entity.performerTypeId = this.performerTypeId;
      }
      if (this.projectFormDomain.ui.validationErrors.list.length === 0 && isFinalySave) {
        this.ui.editApplication.entity.projectId = this.projectFormDomain.ui.model.entity.id;
        this.ui.editApplication.entity.isUnitedWithProject = true;
        this.ui.editApplication.entity.name = this.projectFormDomain.ui.model.entity.name;
      }
    }

    if (
      !this.projectFormDomain ||
      (this.projectFormDomain && this.projectFormDomain.ui.validationErrors.list.length === 0 && isFinalySave)
    ) {
      this.ui.removeSelection();
      this.ui.isDisabledSaveButton.setValue(true);
      let result: any = null;

      if (this.projectFormDomain) {
        this.ui.editApplication.entity.projectId = this.projectFormDomain.ui.model.entity.id;
        this.ui.editApplication.entity.isUnitedWithProject = true;
        this.ui.editApplication.entity.name = this.projectFormDomain.ui.model.entity.name;
      }

      if (this.ui.type.value === ApplicationDetailsType.entity) {
        result = await this.saveApplicationEntity();
      }
      if (this.ui.type.value === ApplicationDetailsType.template) {
        result = await this.saveApplicationTemplate();
      }

      this.ui.isDisabledSaveButton.setValue(false);

      return result;
    }
  };

  saveApplicationEntity = async () => {
    this.removeApplicationValidationErrors();
    this.removeJiraValidationErrors();
    let applicationId = this.ui.application.entity.id;
    let projectId = this.ui.application.entity.projectId || this.ui.editApplication.entity.projectId;
    this.ui.editApplication.entity.projectId = projectId;
    if (applicationId) {
      await this.rootService.application.entity.updateApplicationWithParams(
        {
          newEntity: this.ui.editApplication.entity,
          textFieldsData: this.ui.specificationsTextFieldsValues.list
        }
      ).catch(this.errorsHandler);
      await this.saveApplicationJiraSettings(applicationId);
      // await Promise.all([this.saveApplicationStatuses(), this.saveApplicationAdditionalColumnData()]);
    } else {
      applicationId =
        (await this.rootService.application.entity
          .createApplicationWithParams({
            newEntity: this.ui.editApplication.entity,
            textFieldsData: this.ui.specificationsTextFieldsValues.list
          })
          .catch(this.errorsHandler)) || undefined;
      this.ui.editApplication.entity.id = applicationId;
      this.ui.application.entity.id = applicationId;
      if (applicationId) {
        await this.saveApplicationJiraSettings(applicationId);
        if (this.ui.jiraValidationErrors.list.length === 0 && this.ui.applicationValidationErrors.list.length === 0) {
          const application = await this.rootService.application.entity.getById(applicationId);

          if (!this.ui.isNeedVerifyAllRequirementsOnCreate.value) {
            await this.acceptRequirementsUpdate(application.id, application.dataId);
          }

          this.router.goTo(`/application/${applicationId}/info`);
        }
      }
    }

    if (
      this.ui.jiraValidationErrors.list.length === 0 &&
      this.ui.applicationValidationErrors.list.length === 0 &&
      !this.projectFormDomain
    ) {
      this.ui.isFirstBootLoaded.setValue(false);
      await this.startPage(this.container, ApplicationDetailsMode.view, applicationId, projectId);
    }

    if (
      this.ui.jiraValidationErrors.list.length === 0 &&
      this.ui.applicationValidationErrors.list.length === 0 &&
      this.projectFormDomain
    ) {
      this.ui.isFirstBootLoaded.setValue(false);
      this.router.goTo(`/application/${applicationId}/info`);
    }

    if (this.ui.applicationValidationErrors.list.length) {
      this.ui.editApplication.entity.acceptStatus = ApplicationAcceptStatus.draft;
    }
  };

  saveApplicationTemplate = async () => {
    this.removeApplicationValidationErrors();
    let applicationId = this.ui.application.entity.id;
    const entity = {
      ...this.ui.editApplication.entity,
      customInformation: { fields: this.ui.editApplication.entity?.customInformation?.fields || [] }
    }
    if (applicationId) {
      await this.rootService.application.template
        .updateApplicationTemplateWithParams({
          newEntity: entity,
          textFieldsData: this.ui.specificationsTextFieldsValues.list
        })
        .catch(this.errorsHandler);
    } else {
      await this.rootService.application.template
        .createApplicationTemplateWithParams({
          newEntity: entity,
          textFieldsData: this.ui.specificationsTextFieldsValues.list
        })
        .catch(this.errorsHandler);
    }

    if (this.ui.applicationValidationErrors.list.length === 0) {
      this.router.goTo('/settings/application/template');
    }
  };

  cancelEditApplication = () => {
    if (this.ui.application.entity.id) {
      if (this.ui.type.value === ApplicationDetailsType.template) {
        this.router.goTo(`/settings/application/template`);
      } else {
        if (this.projectFormDomain) {
          this.router.goTo(`/application/${this.ui.application.entity.id}/info`);
        } else {
          this.startPage(
            this.container,
            ApplicationDetailsMode.view,
            this.ui.application.entity.id,
            this.ui.project.entity.id,
          );
        }
      }
    } else {
      if (this.ui.application.entity.projectId) {
        this.router.goTo(`/project/${this.ui.application.entity.projectId}/info`);
      } else {
        if (this.projectFormDomain) {
          this.router.goTo(`/`);
        } else {
          this.router.goTo(`/settings/application/template`);
        }
      }
    }
  };

  acceptRequirementsUpdate = async (applicationId?: string, applicationDataId?: string) => {
    applicationId = applicationId || this.ui.application.entity.id;
    applicationDataId = applicationDataId || this.ui.applicationData.entity.id;
    await this.rootService.application.data.acceptRequirementsUpdate(applicationDataId || '');
    // await this.startPage(ApplicationDetailsMode.view, applicationId);
  };

  getSetStatusValueHandler = (requirementId?: string, statusId?: string, isTestStatus?: boolean, appReqId?: string) => {
    const setStatusData = (requirementId: string = '', statusId: string = '', statusValueId: string = '', appReqId: string = ''): any => {
      const statusDataIndex = this.ui.applicationData.entity.statusesData?.data?.findIndex((statusData) => {
        return statusData.requirementId === requirementId && statusData.statusId === statusId;
      });
      const updatedStatusData = {
        requirementId,
        appReqId,
        statusId,
        statusValueId,
        lastChangeDate: getServerNowDate(),
      };

      if (statusDataIndex !== -1) {
        if (statusDataIndex !== undefined && this.ui.applicationData.entity.statusesData?.data[statusDataIndex]) {
          this.ui.applicationData.entity.statusesData.data[statusDataIndex] = updatedStatusData;
        }
      } else {
        this.ui.applicationData.entity.statusesData?.data.push(updatedStatusData);
      }
    };

    return (newStatusValue: any) => {
      this.ui.isLoading.setValue(true);
      // @todo remove this unblock timeout
      setTimeout(async () => {
        if (isTestStatus) {
          await this.setTestStatus(newStatusValue?.value || '', requirementId || '', appReqId || '');
        }
        this.ui.isLoading.setValue(true);
        const statusValueId = newStatusValue?.value;
        const isActiveSelectedRequirement = this.ui.selectedActiveRequirementsIds.value.includes(appReqId || '');
        if (isActiveSelectedRequirement) {
          this.ui.selectedActiveRequirementsIds.value.forEach((activeSelectedRequirementId) => {
            const reqId = this.ui.applicationReq.list.find((item) => item.id === activeSelectedRequirementId)?.id;
            setStatusData(activeSelectedRequirementId, statusId, statusValueId, reqId);
          });

          const statusesData = {
            data: this.ui.selectedActiveRequirementsIds.value.map((activeSelectedRequirementId) => {
              const reqId = this.ui.applicationReq.list.find((item) => item.id === activeSelectedRequirementId)?.requirementId;
              return {
                requirementId: reqId,
                activeSelectedRequirementId,
                statusId,
                statusValueId,
                appReqId: activeSelectedRequirementId,
                lastChangeDate: getServerNowDate(),
              }
            }),
          } as unknown as IApplicationStatusData;

          await this.saveApplicationStatuses(statusesData);
        } else {
          setStatusData(requirementId, statusId, statusValueId, appReqId);

          await this.saveApplicationStatuses({
            data: [{ requirementId, statusId, statusValueId, lastChangeDate: getServerNowDate(), appReqId }],
          } as unknown as IApplicationStatusData);
        }
        this.rerenderRequirements();
        this.ui.isLoading.setValue(false);
      }, 0);
    };
  };

  async setTestStatus(newStatusValueId: string, requirementId: string, appReqId: string) {
    const dateAdditionalColumnContent = this.ui.additionalColumnsContent.list.find(
      (additionalColumnContent) => additionalColumnContent.content.toLowerCase() === 'плановая дата устранения',
    );
    const dateAdditionalColumnContentId = dateAdditionalColumnContent?.id;

    const commentsAdditionalColumnId = this.ui.additionalColumns.list.find(
      (additionalColumnContent) =>
        additionalColumnContent.name.toLocaleLowerCase() === 'описание выявленного недостатка',
    )?.id;

    const commentsAdditionalColumnsContentIds = this.ui.additionalColumnsContent.list
      .filter((additionalColumnContent) => additionalColumnContent.additionalColumnId === commentsAdditionalColumnId)
      .map((entity) => entity.id);

    const targetAdditionalColumnsContentIdsData = [
      { id: dateAdditionalColumnContentId, columnId: dateAdditionalColumnContent?.additionalColumnId, value: null },
      ...commentsAdditionalColumnsContentIds.map((id) => ({ id, columnId: commentsAdditionalColumnId, value: '' })),
    ];

    const testingStatus = this.ui.statuses.list.find(
      (status) => status.name.toLocaleLowerCase() === 'результат тестирования',
    );

    const prevStatusValueId = this.ui.getStatusData(requirementId, testingStatus?.id)?.statusValueId;

    const activeStatusesNames = [
      'некритичное замечание',
      'критичное замечание',
      'соответствует с замечанием',
      'не соответствует',
    ];
    const activeTestingStatusValuesIds = this.ui.statusesValues.list
      .filter(
        (statusValue) =>
          statusValue.statusId === testingStatus?.id &&
          activeStatusesNames.includes(statusValue.name.toLocaleLowerCase()),
      )
      .map((statusValue) => statusValue.id);

    const prevIsActiveStatus = activeTestingStatusValuesIds.includes(prevStatusValueId);
    const newIsActiveStatus = activeTestingStatusValuesIds.includes(newStatusValueId);

    if ((prevIsActiveStatus && !newIsActiveStatus) || (!prevIsActiveStatus && newIsActiveStatus)) {
      targetAdditionalColumnsContentIdsData.forEach((additionalColumnContentData) => {
        this.ui.setAdditionalColumnData(
          requirementId,
          additionalColumnContentData.columnId,
          additionalColumnContentData.id,
          additionalColumnContentData.value,
          appReqId,
        );
      });
      await this.saveApplicationAdditionalColumnData({
        data: targetAdditionalColumnsContentIdsData.map((additionalColumnContentData) => {
          return {
            requirementId,
            appReqId,
            additionalColumnId: additionalColumnContentData.columnId,
            additionalColumnContentId: additionalColumnContentData.id,
            value: additionalColumnContentData.value,
            originalFileName: null,
            lastChangeDate: getServerNowDate(),
          };
        }),
      } as unknown as IApplicationAdditionalColumnData);
    }
  }

  getSetAdditionalColumnDataValueHandler = (
    requirementId?: string,
    additionalColumnId?: string,
    additionalColumnContentId?: string,
    appReqId?: string,
  ) => {
    let timer: any = null;
    return (value: any, originalFileName: string = '') => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        this.ui.setAdditionalColumnData(
          requirementId,
          additionalColumnId,
          additionalColumnContentId,
          value,
          originalFileName,
          // appReqId,
        );
        await this.saveApplicationAdditionalColumnData({
          data: [
            {
              additionalColumnId,
              additionalColumnContentId,
              value,
              originalFileName,
              lastChangeDate: new Date(),
              requirementId,
              appReqId,
            },
          ],
        } as unknown as IApplicationAdditionalColumnData);
      }, 1000);
    };
  };

  async deleteApplication() {
    const applicationId = this.ui.application.entity.id;
    if (applicationId) {
      await this.rootService.application.entity.deleteById(applicationId);
      if (this.ui.application.entity.isUnitedWithProject) {
        await this.rootService.project.entity.deleteById(this.ui.application.entity.projectId || '');
      }
      if (this.ui.application.entity.isUnitedWithProject) {
        this.router.goTo(`/`);
      } else {
        this.router.goTo(`/project/${this.ui.application.entity.projectId}/info`);
      }
    }
  }

  setStatusesColumns = () => {
    //@todo refactoring with bundles
    const testStatusName = 'Результат тестирования';
    const domain = this;
    const sortByIndex = (current: any, next: any) => (current.orderIndex < next.orderIndex ? -1 : 1);
    const statusesColumns = domain.ui.statuses.list
      .slice()
      .sort(sortByIndex)
      .map((status: IStatusEntityModel) => {
        return {
          title: status.name,
          customSort: domain.ui.sortStatusesColumn(status),
          headerStyle: { paddingLeft: '32px' },
          render: (rowData: IAppReqData) => {
            const isEditMode = domain.ui.mode.value === ApplicationDetailsMode.edit;
            const isNewestRequirement = rowData.requirement?.state === RequirementViewModelState.newest;
            const isTestStatus = status.name.toLocaleLowerCase() === testStatusName.toLocaleLowerCase();
            const isAdmin = domain.layoutDomain.userHaveAnyAccess([
              UserSystemRoleModelPermissionMap['global-allow-any'],
            ]);
            const isNoAccess = !status.rolesAccessIds.includes(domain.ui.activeUserProjectRoleId.value);
            return (
              <StatusAutocomplete
                noPadding={true}
                isDisabled={
                  isAdmin ? isEditMode || isNewestRequirement : isEditMode || isNewestRequirement || isNoAccess
                }
                appReqId={rowData.appReqId}
                rowDataId={rowData.requirement?.id}
                statusId={status.id}
                isTestStatus={isTestStatus}
                domain={domain}
              />
            );
          },
        };
      });

    if (statusesColumns.length <= 2) {
      domain.ui.statusesColumns.setValue(statusesColumns);
    } else {
      const tableColumns = statusesColumns.slice(0, 2);
      const extraTableColumns = statusesColumns.slice(2);

      domain.ui.statusesColumns.setValue(tableColumns);
      domain.ui.extraStatusesColumns.setValue(extraTableColumns);
    }
  };

  async acceptSelectedNewRequirements() {
    const applicationId = this.ui.application.entity.id;
    const applicationDataId = this.ui.applicationData.entity.id || '';
    const newestIds = this.ui.selectedNewRequirementsIds.value;
    this.ui.isLoading.setValue(true);
    for (const index in newestIds) {
      const requirementId = newestIds[index];
      this.ui.toggleSelectRequirement(requirementId);
    }

    await this.rootService.application.data.acceptRequirementNewUpdate(applicationDataId, newestIds);
    this.ui.isLoading.setValue(false);

    await this.startPage(this.container, ApplicationDetailsMode.view, applicationId);
  }

  async acceptSelectedDeleteRequirements() {
    const applicationId = this.ui.application.entity.id;
    const applicationDataId = this.ui.applicationData.entity.id || '';
    const deletedIds = this.ui.selectedDeleteRequirementsIds.value;
    this.ui.isLoading.setValue(true);
    for (const index in deletedIds) {
      const requirementId = deletedIds[index];
      this.ui.toggleSelectRequirement(requirementId);
      const linksUpdate = this.ui.applicationJiraLinks.value.filter((item) => item.requirementId !== requirementId);
      this.ui.applicationJiraLinks.setValue(linksUpdate);
    }
    await this.rootService.application.data.acceptRequirementDeleteUpdate(applicationDataId, deletedIds);
    this.ui.isLoading.setValue(false);
    await this.startPage(this.container, ApplicationDetailsMode.view, applicationId);
  }

  getUpdateFieldHandler(fieldName: string) {
    return (newValue: any) => {
      this.ui.editApplication.setEntity({
        ...this.ui.editApplication.entity,
        [fieldName]: newValue,
      });
    };
  }

  getUpdateSelectFieldHandler(fieldName: string) {
    return (newValue: any) => {
      this.ui.editApplication.setEntity({
        ...this.ui.editApplication.entity,
        [fieldName]: newValue?.value,
      });
      if ((fieldName = 'performerTypeId')) {
        this.performerTypeId = newValue?.value;
      }
      // this.loadApplicationDataPreview();
    };
  }

  onChangeApplicationSpecifications(specificationIds: string[]) {
    this.ui.editApplication.entity.specificationsIds = specificationIds;
  }

  addApplicationValidationErrors(errors: IValidationErrorData[]) {
    this.ui.applicationValidationErrors.setList(errors);
  }

  addJiraValidationErrors(
    errors: IValidationErrorData[],
    isProjectId: boolean = false,
    isIssueType: boolean = true,
    isStatusesMap: boolean = true,
    isPriorityMap: boolean = true,
  ) {
    this.clearJiraLoadedDataValues(isProjectId, isIssueType, isStatusesMap, isPriorityMap);
    this.ui.jiraValidationErrors.setList(errors);
  }

  clearJiraLoadedDataValues(
    isProjectId: boolean,
    isIssueType: boolean,
    isStatusesMap: boolean,
    isPriorityMap: boolean,
    isFiledsData?: boolean,
  ) {
    if (isProjectId && this.ui.editJiraTaskManager.entity.settings?.value) {
      this.ui.editJiraTaskManager.entity.settings.value.projectId = null;
    }
    if (isStatusesMap) {
      this.clearJiraStatusesData();
    }
    if (isIssueType) {
      this.clearJiraIssueTypeData();
    }

    if (isPriorityMap) {
      this.clearJiraPriorityData();
    }

    if (isFiledsData) {
      this.clearJiraFieldsData();
    }
  }

  clearJiraStatusesData() {
    if (this.ui.editJiraTaskManager.entity.settings?.value?.statusesMap) {
      this.ui.editJiraTaskManager.entity.settings.value.statusesMap = [];
      this.ui.editJiraTaskManager.entity.settings.value.allowedInternalStatusesIds = [];
    }
  }

  clearJiraPriorityData() {
    if (this.ui.editJiraTaskManager.entity.settings?.value?.prioritiesMap) {
      this.ui.editJiraTaskManager.entity.settings.value.prioritiesMap = [];
    }
  }

  clearJiraFieldsData() {
    if (this.ui.editJiraTaskManager.entity.settings?.value?.fields) {
      this.ui.editJiraTaskManager.entity.settings.value.fields = [];
    }
  }

  clearJiraIssueTypeData() {
    if (this.ui.editJiraTaskManager.entity.settings?.value?.issue?.type?.id) {
      this.ui.editJiraTaskManager.entity.settings.value.issue.type.id = undefined;
    }
  }

  getApplicationValidationErrorFor(fieldName: string, id?: string) {
    const error = this.ui.applicationValidationErrors.list.find(
      (validationError) => validationError.target === fieldName,
    );
    return error || null;
  }

  getJiraValidationErrorFor(fieldName: string, id?: string) {
    const error = this.ui.jiraValidationErrors.list.find((validationError) => validationError.target === fieldName);
    return error || null;
  }

  async removeApplicationValidationErrors() {
    this.ui.applicationValidationErrors.setList([]);
  }

  async removeJiraValidationErrors() {
    this.ui.jiraValidationErrors.setList([]);
  }

  applicationFormErrorsHandler = async (error: IError) => {
    return this.errorsHandler(error, (data) => this.addApplicationValidationErrors(error.data));
  };

  jiraFormErrorsHandler = async (
    error: IError,
    isProjectId: boolean = false,
    isIssueType: boolean = true,
    isStatusesMap: boolean = true,
    isPriorityMap: boolean = true,
  ) => {
    return this.errorsHandler(error, (data) =>
      this.addJiraValidationErrors(error.data, isProjectId, isIssueType, isStatusesMap, isPriorityMap),
    );
  };

  errorsHandler = async (error: IError, errorHandler?: (data: any) => void): Promise<void> => {
    if (error.webCode === '400') {
      errorHandler ? errorHandler(error.data) : this.addApplicationValidationErrors(error.data);
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Ошибка валидации',
      });
    } else {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Неизвестная ошибка',
      });
    }
  };

  protected async callService(serviceHandler: Promise<any>): Promise<any> {
    try {
      const result = await serviceHandler;
      return result;
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  async downloadApplicationProtocolReport({
    format = 'docx',
    audit,
    reportName = '',
    versionNumber,
  }: {
    format: string;
    audit?: ICoreSearchAudit;
    reportName: string;
    versionNumber?: string;
  }) {
    switch (format) {
      case 'pdf':
        const blobPdf = await this.rootService.reports.getReport(
          this.ui.application.entity.name,
          'protocol',
          'pdf',
          this.ui.application.entity.id,
          versionNumber,
          audit,
        );
        this.saveFile(
          reportName ? `${reportName}.pdf` : `АС ${this.ui.application.entity.name}_Протокол ПСИ ИБ.pdf`,
          blobPdf,
        );
        break;
      case 'docx':
      default:
        const blobDocx = await this.rootService.reports.getReport(
          this.ui.application.entity.name,
          'protocol',
          'docx',
          this.ui.application.entity.id,
          versionNumber,
          audit,
        );
        this.saveFile(
          reportName ? `${reportName}.docx` : `АС ${this.ui.application.entity.name}_Протокол ПСИ ИБ.docx`,
          blobDocx,
        );
        break;
    }
  }

  async downloadApplicationTestProgramReport({
    format = 'docx',
    versionNumber,
    audit,
    reportName = '',
  }: {
    format: string;
    versionNumber?: string;
    audit?: ICoreSearchAudit;
    reportName: string;
  }) {
    switch (format) {
      case 'pdf':
        const blobPdf = await this.rootService.reports.getReport(
          this.ui.application.entity.name,
          'testing',
          'pdf',
          this.ui.application.entity.id,
          versionNumber,
          audit,
        );
        this.saveFile(reportName ? `${reportName}.pdf` : `АС ${this.ui.application.entity.name}_ПИМИ ИБ.pdf`, blobPdf);
        break;
      case 'docx':
      default:
        const blobDocx = await this.rootService.reports.getReport(
          this.ui.application.entity.name,
          'testing',
          'docx',
          this.ui.application.entity.id,
          versionNumber,
          audit,
        );
        this.saveFile(
          reportName ? `${reportName}.docx` : `АС ${this.ui.application.entity.name}_ПИМИ ИБ.docx`,
          blobDocx,
        );
        break;
    }
  }

  async downloadApplicationRequirementsReport({
    format = 'docx',
    versionNumber,
    audit,
    isNeedColor = false,
    reportName,
  }: IDownloadReportProps) {

    switch (format) {
      case 'pdf':
        const blobPdf = await this.rootService.reports.getReport(
          this.ui.application.entity.name,
          'requirements',
          'pdf',
          this.ui.application.entity.id,
          versionNumber,
          audit,
          isNeedColor,
        );

        this.saveFile(
          reportName ? `${reportName}.pdf` : `АС ${this.ui.application.entity.name}_Требования ИБ.pdf`,
          blobPdf,
        );
        break;
      case 'docx':
      default:
        const blobDocx = await this.rootService.reports.getReport(
          this.ui.application.entity.name,
          'requirements',
          'docx',
          this.ui.application.entity.id,
          versionNumber,
          audit,
          isNeedColor,
        );
        this.saveFile(
          reportName ? `${reportName}.docx` : `АС ${this.ui.application.entity.name}_Требования ИБ.docx`,
          blobDocx,
        );
        break;
    }
  }

  async downloadApplicationQuestionnaire({
    format = 'docx',
    audit,
    isNeedColor = false,
    reportName = '',
    versionNumber,
  }: {
    format: string;
    audit?: ICoreSearchAudit;
    isNeedColor?: boolean;
    reportName?: string;
    versionNumber?: string;
  }) {
    switch (format) {
      case 'pdf':
        const blobPdf = await this.rootService.project.entity.getReport(
          this.ui.application.entity.id,
          'pdf',
          this.ui.application.entity.name,
          audit,
          isNeedColor,
          versionNumber,
        );
        this.saveFile(reportName ? `${reportName}.pdf` : `АС ${this.ui.application.entity.name} - Анкета АС.pdf`, blobPdf);
        break;
      case 'docx':
      default:
        const blobDocx = await this.rootService.project.entity.getReport(
          this.ui.application.entity.id,
          'docx',
          this.ui.application.entity.name,
          audit,
          isNeedColor,
        );

        this.saveFile(reportName ? `${reportName}.docx` : `АС ${this.ui.application.entity.name} - Анкета АС.docx`, blobDocx);
        break;
    }
  }

  saveFile(filename: string, blob: any) {
    const blobUrl = URL.createObjectURL(blob);

    let link: any = document.createElement('a');
    link.download = filename;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  async reloadRequirementData(requirementId: string) {
    const requirement = await this.rootService.requirement.entity.getById(requirementId);
    const requirementIndex = this.ui.requirements.list.findIndex((requirement) => requirement.id === requirementId);
    if (requirementIndex !== -1) {
      this.ui.requirements.list[requirementIndex] = requirement;
    }

    const filteredRequirementIndex = this.ui.renderRequirements.list.findIndex(
      (requirement) => requirement.id === requirementId,
    );
    if (filteredRequirementIndex !== -1) {
      this.ui.renderRequirements.list[requirementIndex] = {
        ...this.ui.renderRequirements.list[requirementIndex],
        ...requirement,
      };
    }
  }

  async addMessage(
    text: string,
    requirementId: string,
    userChat: IChatModel,
    replyMessage: IMessageModel | null,
  ): Promise<IMessageModel> {
    const message = {
      chatId: userChat.rootChatId || '',
      senderId: this.layoutDomain.ui.activeUser.entity.id,
      text,
      chatUserAvatarUrl: null,
      chatUserId: this.layoutDomain.ui.activeUser.entity.id,
      chatUserFullName: this.layoutDomain.ui.activeUser.entity.displayName,
      isInternal: true,
      taskManagerId: null,
      taskManagerLink: null,
      ...(replyMessage && { parentId: replyMessage.id }),
    };

    return await this.rootService.application.data.addMessage(
      requirementId,
      this.ui.applicationData.entity.id || '',
      message,
    );
  }

  async updateMessage(requirementId: string, message: IMessageModel): Promise<IMessageModel[]> {
    return this.callService(
      this.rootService.application.data.editMessage(requirementId, this.ui.applicationData.entity.id || '', message),
    );
  }

  async deleteMessage(requirementId: string, messageId: string, applicationRequirementId: string): Promise<IMessageModel[]> {
    return this.callService(
      this.rootService.application.data.deleteMessage(
        requirementId,
        this.ui.applicationData.entity.id || '',
        messageId,
        applicationRequirementId
      ),
    );
  }

  async readMessages(userChatId: string, messagesIds: string[], requirementId: string) {
    if (!this.ui.readMessageSetInterval.value) {
      const interval = setInterval(() => {
        const messagesIds = [...this.ui.readMessagesPoolIds.value];
        if (this.ui.readMessagesPoolIds.value.length > 0) {
          this.callService(this.rootService.application.data.readMessages(userChatId, messagesIds));
          this.ui.readMessagesPoolIds.setValue([]);
        }
        this.ui.readMessageSetInterval.setValue(interval);
      }, 1000);
    }
    this.ui.readMessagesPoolIds.value.push(...messagesIds);
    if (this.ui.applicationNewMessagesState.value?.applicationNewMessages) {
      this.ui.applicationNewMessagesState.value.applicationNewMessages -= messagesIds.length;
    }
    if (
      this.ui.applicationNewMessagesState.value?.requirementNewMessages?.find(
        (item) => item.requirementId === requirementId,
      )
    ) {
      const index = this.ui.applicationNewMessagesState.value?.requirementNewMessages.findIndex(
        (item) => item.requirementId === requirementId,
      );
      this.ui.applicationNewMessagesState.value.requirementNewMessages[index].newMessages -= messagesIds.length;
    }
    this.layoutDomain.ui.activeUser.entity.newMessagesCount -= messagesIds.length;
  }

  async loadChatMessagesForRequirement(requirementId: string): Promise<IMessageModel[]> {
    return this.callService(
      this.rootService.application.data.loadUserChatMessages(requirementId, this.ui.applicationData.entity.id || ''),
    );
  }

  async loadChatForRequirement(requirementId: string): Promise<IChatModel> {
    return this.callService(
      this.rootService.application.data.loadUserChat(requirementId, this.ui.applicationData.entity.id || ''),
    );
  }

  syncProjectAndApplicationDomains() {
    if (this.projectFormDomain) {
      this.projectFormDomain.applicationDomain.setEntity(this);
    }
  }

  getValidationErrorForEmptyPerformerId() {
    this.layoutDomain.notifications.showNotification({
      type: LayoutNotificationType.error,
      text: 'Ошибка валидации',
    });
    const performerTypeError = {
      message: 'Значение не должно быть пустым',
      target: 'performerTypeId',
    };
    this.addApplicationValidationErrors([performerTypeError]);
  }

  async onEditCategoryTextField({ category, newValue }: { category: ISpecificationCategoryModel, newValue?: string }) {
    const valuesList = this.ui.specificationsTextFieldsValues.list
    const currentFieldValue = valuesList.find(value => value.categoryId === category.id)
    if (!newValue) {
      let newValueList = valuesList.filter(value => value.categoryId !== category?.id)
      this.ui.specificationsTextFieldsValues.setList(newValueList)
    }
    if (currentFieldValue && newValue) {
      currentFieldValue.name = newValue
      currentFieldValue.description = category.name
      let newValueList = valuesList.filter(value => value.categoryId !== currentFieldValue.categoryId)
      newValueList.push(currentFieldValue)
      this.ui.specificationsTextFieldsValues.setList(newValueList)
    }
    if (!currentFieldValue && newValue) {
      const newSpecification: ISpecificationEntityModel = {
        name: newValue,
        description: category.name,
        categoryId: category.id,
        isActive: true,
      }
      valuesList.push(newSpecification)
      this.ui.specificationsTextFieldsValues.setList(valuesList)
    }
  }

  async getSpecificationsTextFieldsData(templateId?: string) {
    const applicationId = this.ui.editApplication.entity?.id || this.ui.application.entity.id

    if (applicationId) { // редактирование анкеты
      const currentSpecifications = this.ui.editSpecifications.list.filter(item => item.applicationId === applicationId)
      this.ui.specificationsTextFieldsValues.setList(currentSpecifications)
    } if (!applicationId && templateId) { // созадние анкеты с шаблоном
      const currentSpecifications = this.ui.editSpecifications.list.filter(item => item.applicationId === templateId)
      const specificationCategories = this.ui.editSpecificationsCategories.list
      const specificationsForTemplate = currentSpecifications.map(spec => {
        const currentCategory = specificationCategories.find(category => category.id === spec.categoryId && category.isActive)
        const newSpecification: ISpecificationEntityModel = {
          name: spec.name,
          description: currentCategory?.name || spec.description,
          categoryId: spec.categoryId,
          isActive: true,
        }
        return newSpecification
      })

      const currentSpecificationsIds = currentSpecifications.map(item => item.id)
      const templateSpecIds = this.ui.editApplication.entity?.specificationsIds
      const clearSpecArray = templateSpecIds?.filter(specId => !currentSpecificationsIds.includes(specId))

      await this.ui.editApplication.setEntity({
        ...this.ui.editApplication.entity,
        specificationsIds: clearSpecArray
      })
      await this.ui.specificationsTextFieldsValues.setList(specificationsForTemplate)
    } if (!applicationId && !templateId) { // созадние анкеты без шаблона
      const defaultTextFields: ISpecificationEntityModel[] = []
      this.ui.editSpecificationsCategories.list.forEach(specCategory => {
        if (specCategory.categoryType === SpecificationCategoryType.specificationCategoryText && specCategory.conditionsTextMap?.defaultValue && specCategory.isActive) {
          const newSpecification: ISpecificationEntityModel = {
            name: specCategory.conditionsTextMap?.defaultValue,
            description: specCategory.name,
            categoryId: specCategory.id,
            isActive: true,
          }
          defaultTextFields.push(newSpecification)
        }
      })
      this.ui.specificationsTextFieldsValues.setList(defaultTextFields)
    }
  }

  async updateSpecificationsDependence() {
    const editSpecifications = await this.rootService.specification.entity.search({ limit: 100000 })
    const editSpecificationsCategories = await this.rootService.specification.category.search({ limit: 100000 })
    this.ui.editSpecifications.setList((editSpecifications?.data as any) || []);
    this.ui.editSpecificationsCategories.setList(editSpecificationsCategories.data);
  }
}
