import { SpecificationEntityAdminUI } from './SpecificationEntityAdminUI';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { SpecificationEntityModalDomain } from './modal/SpecificationEntityModalDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ISpecificationEntityModel } from './../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ISpecificationEntitySearch } from './../../../../../../service/specification/entity/ISpecificationeEntitySearch';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { SpecificationCategoryType } from '../../../../../../service/specification/category/ISpecificationCategoryModelConditionMap';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class SpecificationEntityAdminDomain extends DataTableDomain<
  ISpecificationEntityModel,
  ISpecificationEntitySearch
> {
  public ui: SpecificationEntityAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.specification.entity);
    this.ui = new SpecificationEntityAdminUI(this, null);
    this.modalDomain = new SpecificationEntityModalDomain(rootPrivateServices.specification.entity, layoutDomain, this);
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Характеристики АС');
    this.ui.tableSubtitle.setValue('Значения');
  }

  async loadData() {
    await this.loadDepenicies();
    await super.loadList();
    const entities = this.ui.rows.entities.list
    const valueEntities = entities.filter((item) => !item.applicationId)
    await this.ui.rows.entities.setList(valueEntities)
    await this.ui.rows.filteredEntities.setList(valueEntities);
    const sortEggsInNest = (a: number, b: number) => {
      return a > b ? 1 : b > a ? -1 : 0;
    };
    this.ui.tableOptions.rowsPerPageOptions.setList(
      [this.ui.rows.entities.list.length, 20, 100].sort(sortEggsInNest),
    );
  }

  async loadDepenicies() {
    const categoriesSearchResult = await this.rootPrivateServices.specification.category.search({ limit: 1000000 });
    const categoriesSearchResultWithoutTextFields = categoriesSearchResult.data.filter((item) => item.categoryType === SpecificationCategoryType.specificationCategoryValue) || []
    this.ui.categories.setList(categoriesSearchResultWithoutTextFields);
  }

  async setPermissions() {
    await super.setPermissions();

    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-specifications-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-specifications-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-specifications-update']]),
    );
  }
}
